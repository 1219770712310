<template>
  <v-row justify="center">
    <v-dialog
      v-model="isSubmitAdjustmentModalOpen"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="text-h4 font-weight-medium">
          {{ $t("stockAdjustments.request_title") }}
        </v-card-title>
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            outlined
            color="indigo"
            text
            @click="toggleSubmitAdjustment"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="primary"
            text
            @click="submitOrder()"
            :loading="isLoading"
          >
            {{ $t("stockAdjustments.submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import stockAdjustmentService from "@/store/services/stock-adjustment-service";
export default {
  props: {
    isSubmitAdjustmentModalOpen: Boolean,
    toggleSubmitAdjustment: Function,
    currentStockAdjustment: Object,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  watch: {},
  computed: {
    console: () => console,
  },
  methods: {
    async submitOrder() {
      try {
        this.isLoading = true;
        const data = await stockAdjustmentService.submit({
          stock_adjustment_id: this.currentStockAdjustment.id,
        });

        const AdjustmentSlip = await stockAdjustmentService.getAdjustmentSlip(
          this.currentStockAdjustment
        );

        const url = window.URL.createObjectURL(new Blob([AdjustmentSlip]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${data.stock_adjustment.tracking_number}.pdf`
        );
        document.body.appendChild(link);
        // window.open(link);
        link.click();
        this.$store.commit("stockAdjustments/STOCKADJUSTMENT", {});
        this.$store.commit("products/CLEAN_LIST");
        this.$swal("", "Adjustment created successfully", "success");
        this.$router.push("/stock-adjustments");
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        this.$store.dispatch(
          "alerts/error",
          error?.response?.data?.message ?? "server error"
        );
      }
    },
  },
};
</script>